import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { Assembly, RoleLabelMapping, Roles, Team } from '@assembly/types'
import {
  AssemblySearchAndSelect,
  TeamSearchAndSelect,
} from '@assembly/components'
import validator from 'validator'
import { inviteTeamMember } from '@assembly/api/team'
import { getApiErrorMsg } from '@assembly/utils'

interface InviteTeamMemberDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function InviteTeamMemberDialogTitle(props: InviteTeamMemberDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface InviteTeamMemberDialogProps {
  open: boolean
  onClose: () => void
}

type FormValuesTypes = {
  assembly: Assembly | null
  team: Team | null
  email: string
  role: string
  invalid_assembly: boolean
  invalid_team: boolean
  invalid_email: boolean
  invalid_role: boolean
}

export default function InviteTeamMemberDialog(
  props: InviteTeamMemberDialogProps
) {
  const { open, onClose } = props
  const formInitialValues: FormValuesTypes = {
    assembly: null,
    team: null,
    email: '',
    role: '',
    invalid_assembly: false,
    invalid_team: false,
    invalid_email: false,
    invalid_role: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const { addAlert } = useSnackbars()
  const [isSaving, setIsSaving] = React.useState<boolean>(false)

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.assembly === null ||
      formValues.team === null ||
      formValues.email.length === 0 ||
      !validator.isEmail(formValues.email) ||
      formValues.role.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_assembly: formValues.assembly === null,
        invalid_team: formValues.team === null,
        invalid_email:
          formValues.email.length === 0 || !validator.isEmail(formValues.email),
        invalid_role: formValues.role.length === 0,
      })
      return
    }

    setIsSaving(true)
    const params = new URLSearchParams()
    params.append('email', formValues.email)
    params.append('role', formValues.role)
    try {
      await inviteTeamMember(formValues.assembly.id, formValues.team.id, params)
      setIsSaving(false)
      addAlert({
        message: `Sent invite to ${formValues.email}}`,
        type: SnackbarType.Success,
      })
      handleClose()
    } catch (error: any) {
      setIsSaving(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeAssembly = (value: Assembly | null) => {
    setFormValues({
      ...formValues,
      assembly: value,
      invalid_assembly: false,
    })
  }

  const handleChangeTeam = (value: Team | null) => {
    setFormValues({
      ...formValues,
      team: value,
      invalid_team: false,
    })
  }

  const handleChangeRole = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setIsSaving(false)
    onClose()
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <InviteTeamMemberDialogTitle onClose={handleClose}>
          Invite Team Member
        </InviteTeamMemberDialogTitle>
        <DialogContent dividers>
          <Box>
            <AssemblySearchAndSelect
              value={formValues.assembly}
              onChange={handleChangeAssembly}
              error={formValues.invalid_assembly}
              helperText={formValues.invalid_assembly ? 'Required' : ''}
            />
          </Box>
          <Box marginTop="22px">
            <TeamSearchAndSelect
              value={formValues.team}
              onChange={handleChangeTeam}
              error={formValues.invalid_team}
              helperText={formValues.invalid_team ? 'Required' : ''}
            />
          </Box>
          <TextField
            value={formValues.email}
            margin="dense"
            label="Email"
            type="email"
            name="email"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_email}
            helperText={
              formValues.invalid_email
                ? formValues.email.length === 0
                  ? 'Required'
                  : 'Invalid email'
                : ''
            }
            sx={{ margin: 0, marginTop: '22px' }}
          />
          <Box marginTop="20px">
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                value={formValues.role}
                label="Role"
                name="role"
                onChange={handleChangeRole}
                error={formValues.invalid_role}
              >
                {Object.values(Roles).map((value) => (
                  <MenuItem key={value} value={value}>
                    {RoleLabelMapping[value]}
                  </MenuItem>
                ))}
              </Select>
              {formValues.invalid_role && (
                <FormHelperText error={formValues.invalid_role}>
                  Required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
