import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { theme } from '@assembly/theme'
import { ThemeProvider } from '@mui/material/styles'
import { SideNavbar } from '@assembly/components'
import { RoutePaths } from '@assembly/constants'
import { AppProvider, SnackBarProvider } from '@assembly/contexts'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  AssemblyTemplates,
  Login,
  AssemblyTemplate,
  Assemblies,
  Assembly,
  Team,
  BillingSetup,
} from '@assembly/pages'
import { checkSessionIsValid } from './api/auth'
import React from 'react'
import { User } from '@assembly/types'
import { IntercomProvider, useIntercom } from 'react-use-intercom'

function App() {
  const didMountRef = React.useRef(false)
  const [showLogin, setShowLogin] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [userInfo, setUserInfo] = React.useState<User | null>(null)
  const { boot, shutdown, hide, show, update } = useIntercom()
  const internAdminBase = process.env.REACT_APP_ENV_INTERNAL_ADMIN_BASE_URL

  React.useEffect(() => {
    if (!didMountRef.current) {
      checkSessionIsValidAsync()
      didMountRef.current = true
    }
  }, [])

  const checkSessionIsValidAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await checkSessionIsValid()
      setUserInfo(data)
      setIsLoading(false)
      if (data) {
        // boot intercom
        boot({
          name: data.name,
          email: data.email,
          phone: data.phone,
          customAttributes: {
            org_id: data.organizationID,
            org_link: internAdminBase + '/orgs/' + data.organizationID,
            team_member_id: data.id,
            team_member_name: data.name,
            team_member_role: data.role,
          },
        })
        // console.log('---------------------')
        // console.log('Sending to intercom')
        // console.log({
        //   name: data.name,
        //   email: data.email,
        //   phone: data.phone,
        //   customAttributes: {
        //     org_id: data.organizationID,
        //     org_link: internAdminBase + '/orgs/' + data.organizationID,
        //     team_member_id: data.id,
        //     team_member_name: data.name,
        //     team_member_role: data.role,
        //   },
        // })
        // console.log('---------------------')
      }
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)

      if (error.response && error.response.status === 403) {
        setShowLogin(true)
      }
    }
  }

  const renderContent = () => {
    if (showLogin) {
      return <Login />
    }

    if (isLoading) {
      return <div></div>
    }

    return (
      <BrowserRouter>
        <SideNavbar>
          <Routes>
            <Route
              path={RoutePaths.Root}
              element={<Navigate to={RoutePaths.Assemblies} replace />}
            />
            <Route
              path={RoutePaths.AssemblyTemplates}
              Component={AssemblyTemplates}
            />
            <Route
              path={RoutePaths.AssemblyTemplate}
              Component={AssemblyTemplate}
            />
            <Route path={RoutePaths.Assemblies} Component={Assemblies} />
            <Route path={RoutePaths.Assembly} Component={Assembly} />
            <Route path={RoutePaths.Team} Component={Team} />
            <Route path={RoutePaths.BillingSetup} Component={BillingSetup} />
          </Routes>
        </SideNavbar>
      </BrowserRouter>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AppProvider user={userInfo as User}>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>{renderContent()}</SnackBarProvider>
        </ThemeProvider>
      </AppProvider>
    </LocalizationProvider>
  )
}

export default App
