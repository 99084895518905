import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { NoDataMsg, SearchInputAndButton } from '@assembly/components'
import CreateAssemblyDialog from './CreateAssemblyDialog'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getAllAssemblyTemplates } from '@assembly/api/assemblyTemplate'
import { SnackbarType } from '@assembly/contexts'
import AssemblyTemplatesList from './AssemplyTemplatesList'
import { AssemblyTemplate } from '@assembly/types'
import { getApiErrorMsg } from '@assembly/utils'

const AssemblyTemplates: React.FC = () => {
  const [
    createAssemblyTemplatesDialogIsVisible,
    setCreateAssemblyTemplatesDialogIsVisible,
  ] = React.useState<boolean>(false)

  const { store, setAssemblyTemplates } = useAppStore()
  const { addAlert } = useSnackbars()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [searchResults, setSearchResults] = React.useState<AssemblyTemplate[]>()
  const didMount = React.useRef(false)

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!store.isAssemblyTemplatesLoaded) {
        getAssemblyTemplatesAsync()
      } else {
        setIsLoading(false)
      }
    }
  }, [])

  const getAssemblyTemplatesAsync = async () => {
    try {
      setIsLoading(true)
      const payload = {
        search: '',
      }
      const { data } = await getAllAssemblyTemplates(payload)
      setAssemblyTemplates(data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleCloseAssemblyTemplatesDialog = () => {
    setCreateAssemblyTemplatesDialogIsVisible(false)
  }

  const handleClickCreateAssemblyTemplates = () => {
    setCreateAssemblyTemplatesDialogIsVisible(true)
  }

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      if (value) {
        const filtered = store.assemblyTemplates.filter(
          (assemblyTemplate) =>
            assemblyTemplate.name.toLowerCase().indexOf(value.toLowerCase()) >
            -1
        )
        setSearchResults(filtered)
      } else {
        setSearchResults(undefined)
      }
    },
    [store.assemblyTemplates]
  )

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        inputPlaceholder="Search Assembly Templates"
        buttonLabel="Create Your Own Assembly"
        onClickButton={handleClickCreateAssemblyTemplates}
        onChangeSearch={handleChangeSearch}
      />
      <AssemblyTemplatesList
        assemblyTemplates={searchResults || store.assemblyTemplates}
      />
      {store.assemblyTemplates.length === 0 && (
        <NoDataMsg>No assembly templates found</NoDataMsg>
      )}
      <CreateAssemblyDialog
        open={createAssemblyTemplatesDialogIsVisible}
        onClose={handleCloseAssemblyTemplatesDialog}
      />
    </Box>
  )
}

export default AssemblyTemplates
