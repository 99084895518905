import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { ListItemButton } from '@mui/material'
import { RoleLabelMapping, TeamMember } from '@assembly/types'
import { capitalize } from 'lodash'

interface MembersListProps {
  teamMembers: TeamMember[]
}

export default function MembersList(props: MembersListProps) {
  const handleClickTeamMember = (id: string) => {
    // On Click Logic
  }

  return (
    <List>
      {props.teamMembers.map((teamMember, index) => (
        <div key={index}>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              onClick={() => handleClickTeamMember(teamMember.id)}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: '#1b17f514', color: '#000', fontWeight: 500 }}
                >
                  {teamMember.name
                    ? teamMember.name[0]
                    : capitalize(teamMember.email[0])}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={teamMember.name || teamMember.email}
                secondary={RoleLabelMapping[teamMember.role] || ''}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}
