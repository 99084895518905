import { useAppStore, useSnackbars } from '@assembly/hooks'
import {
  Avatar,
  Box,
  CircularProgress,
  colors,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { InfoItem, LinkButton } from '@assembly/components'
import {
  formatNumbersWithCommas,
  getApiErrorMsg,
  getMediaUrl,
  stringAvatar,
} from '@assembly/utils'
import { capitalize } from 'lodash'
import {
  acceptStartDate,
  getAssembly,
  getSubscriptionPortal,
} from '@assembly/api/assembly'
import {
  Allocation,
  AssemblyLineStatus,
  Assembly as IAssemblyLine,
  RoleLabelMapping,
  TeamMember,
} from '@assembly/types'
import moment from 'moment'
import EastIcon from '@mui/icons-material/East'
import { theme } from '@assembly/theme'
import ReactMarkdown from 'react-markdown'
import { useIntercom } from 'react-use-intercom'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { styled } from '@mui/material/styles'
import ApproveDialog from './ApproveDialog'
import { SnackbarType } from '@assembly/contexts'

const StyledLoadingButton = styled(LoadingButton)(({}) => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const StartDateBox = styled(Box)(({}) => ({
  backgroundColor: colors.grey[50],
  padding: '15px',
  borderRadius: '10px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  marginBottom: '15px',
}))

const StartDateBtnsRow = styled(Box)(({}) => ({
  display: 'flex',
  gap: '10px',
}))

const StyledListItem = styled(ListItem)(({}) => ({
  padding: 0,
  border: '1px solid',
  borderColor: colors.grey[200],
  marginBottom: '15px',
  borderRadius: '10px',
}))

const Row = styled(Box)(({}) => ({
  display: 'flex',
  gap: '15px',
}))

const status = [
  'Initialized',
  'Setup',
  'Proposal',
  'Provisioning',
  'Ready',
  'Active',
]

const Assembly: React.FC = () => {
  const { setAppBarTitle, store } = useAppStore()
  const didMount = React.useRef(false)
  const params = useParams()
  const [data, setData] = React.useState<IAssemblyLine>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [subscriptionPortal, setSubscriptionPortal] = React.useState<string>()
  const { update, showNewMessage } = useIntercom()
  const internAdminBase = process.env.REACT_APP_ENV_INTERNAL_ADMIN_BASE_URL
  const [approveDialogOpen, setApproveDialogOpen] = React.useState(false)
  const [selectedAllocationIndex, setSelectedAllocationIndex] =
    React.useState(-1)
  const { addAlert } = useSnackbars()
  const [acceptStartDateLoading, setAcceptStartDateLoading] =
    React.useState(false)

  React.useEffect(() => {
    if (!didMount.current) {
      getDataAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getDataAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAssembly(params.id || '')
      if (data!.subscriptionSetupComplete) {
        const subsctionPortalRes = await getSubscriptionPortal(params.id || '')
        setSubscriptionPortal(subsctionPortalRes.data.message)
      }
      setAppBarTitle(data.name)
      setData(data)
      setIsLoading(false)
      // boot intercom
      let asanaLink = data.projectManagement?.projectManagementLink
      update({
        customAttributes: {
          assemblyId: data.id,
          assembly_name: data.name,
          assembly_link: internAdminBase + '/assembly-lines/' + data.id,
          assembly_status: data.status,
          assembly_project_management_link: asanaLink,
        },
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  const handleClickAllocation = (id: string) => {
    if (!id) return

    const partnerDashboardUrl = process.env.REACT_APP_ENV_PARTNER_DASHBOARD_URL
    window.open(`${partnerDashboardUrl}/talent/${id}`, '_blank')
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  const statusIndex = status.indexOf(capitalize(data?.status) || '')

  const onUpdate = (allocation: Allocation) => {
    if (!data) return

    data.allocations[selectedAllocationIndex].approved = true
    data.allocations[selectedAllocationIndex].approvedBy = allocation.approvedBy

    setData({ ...data })

    handleCloseApproveDialog()
  }

  const handleClickApproveAllocation = async (index: number, event: any) => {
    event.stopPropagation()
    setSelectedAllocationIndex(index)
    setApproveDialogOpen(true)
  }

  const handleClickRequestChange = (event: any) => {
    event.stopPropagation()
    showNewMessage(
      'Hey ! I want to request a change to my assigned assembly partner'
    )
  }

  const handleClickRequestChangeStartDate = (event: any) => {
    event.stopPropagation()
    showNewMessage(
      'Hello there ! I’d like to request a change to the assembly start date. I’d really prefer it to be on ..'
    )
  }

  const handleCloseApproveDialog = () => {
    setApproveDialogOpen(false)
    setSelectedAllocationIndex(-1)
  }

  const handleClickAcceptStartDate = async () => {
    try {
      setAcceptStartDateLoading(true)
      const payload = new URLSearchParams()
      payload.append('approver', store.userInfo?.id as string)
      const { data } = await acceptStartDate(params.id as string, payload)
      console.log('Data::', data)
      setData(data)
      addAlert({
        message: 'Start date accepted successfully',
        type: SnackbarType.Success,
      })
      setAcceptStartDateLoading(false)
    } catch (error) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setAcceptStartDateLoading(false)
    }
  }

  return (
    <Box>
      <Box
        marginBottom={2}
        sx={{
          backgroundColor: colors.grey[50],
          padding: 2,
          borderRadius: 2,
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" marginBottom={1}>
          What comes next?
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          marginTop={1}
          sx={{
            border: '1px solid',
            borderColor: colors.grey[200],
            width: 'fit-content',
            borderRadius: '5px',
          }}
        >
          {status.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor:
                  index <= statusIndex ? theme.palette.primary.main : 'white',
                color:
                  index <= statusIndex ? 'white' : theme.palette.primary.main,
                borderTopLeftRadius: index === 0 ? '5px' : 0,
                borderBottomLeftRadius: index === 0 ? '5px' : 0,
                borderTopRightRadius: index === status.length - 1 ? '5px' : 0,
                borderBottomRightRadius:
                  index === status.length - 1 ? '5px' : 0,
              }}
              padding={2}
              display="flex"
            >
              <Typography sx={{ fontWeight: index <= statusIndex ? 600 : 400 }}>
                {item}
              </Typography>
              {index === status.length - 1 ? null : (
                <EastIcon sx={{ marginLeft: 2 }} />
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Typography variant="h5" fontWeight={500}>
        {data?.status === AssemblyLineStatus.Proposal
          ? 'Proposed Assembly Partners'
          : 'Assembly Partners'}
      </Typography>
      {data?.allocations.length === 0 && (
        <Typography marginTop={1.2} marginBottom={2}>
          There are no{' '}
          {data?.status === AssemblyLineStatus.Proposal ? 'proposed' : ''}{' '}
          assembly partners available in this assembly.
        </Typography>
      )}
      <List>
        {data?.allocations.map((allocation, index) => (
          <StyledListItem
            onClick={() => {
              handleClickAllocation(allocation.resource?.id as string)
            }}
            secondaryAction={
              <Box sx={{ display: 'flex', gap: '10px' }}>
                {!allocation.approved && (
                  <StyledLoadingButton
                    variant="contained"
                    color="success"
                    onClick={(event) =>
                      handleClickApproveAllocation(index, event)
                    }
                  >
                    Approve
                  </StyledLoadingButton>
                )}
                <StyledLoadingButton
                  variant="contained"
                  onClick={handleClickRequestChange}
                >
                  Request Change
                </StyledLoadingButton>
              </Box>
            }
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(allocation.resource?.firstName || '')}
                  src={getMediaUrl(
                    allocation.resource?.profilePicture?.media || ''
                  )}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  allocation.resource?.firstName +
                  ' ' +
                  allocation.resource?.lastName
                }
                secondary={allocation.resource?.label}
              />
            </ListItemButton>
          </StyledListItem>
        ))}
      </List>
      <Row>
        <StartDateBox>
          <Box>
            <Typography variant="h6">Monthly Subscription Cost</Typography>
            <Typography>
              <b>
              {data?.monthlySubscriptionCost
                  ? '$' + formatNumbersWithCommas(data?.monthlySubscriptionCost) + ' USD / month'
                  : '---'}
              </b>
            </Typography>
            <Typography marginTop={5} variant="h6">Start Date</Typography>
            <Typography>
              <b>
              {data?.startDate && data.startDate !== -1
                ? moment(data?.startDate).format('DD-MMMM-YYYY')
                : '---'}
              </b>
            </Typography>
          </Box>

          <StartDateBtnsRow>
            {data && data?.status === AssemblyLineStatus.Proposal && (
              <StyledLoadingButton
                loading={acceptStartDateLoading}
                color="success"
                variant="contained"
                onClick={handleClickAcceptStartDate}
              >
                Accept
              </StyledLoadingButton>
            )}
            <StyledLoadingButton
              color="primary"
              variant="contained"
              onClick={handleClickRequestChangeStartDate}
            >
              Request Change
            </StyledLoadingButton>
          </StartDateBtnsRow>
        </StartDateBox>
        {/*<InfoItem*/}
        {/*  label="End Date"*/}
        {/*  value={*/}
        {/*    data?.endDate && data.endDate !== -1*/}
        {/*      ? moment(data?.endDate).format('DD-MMMM-YYYY')*/}
        {/*      : '---'*/}
        {/*  }*/}
        {/*/>*/}
      </Row>
      <InfoItem
        label="Description"
        value={
          data?.description ? (
            <ReactMarkdown children={data?.description || ''} />
          ) : (
            '---'
          )
        }
      />
      <InfoItem
        label="Capabilities"
        value={
          data?.capabilities?.map((capability) => capability.name).join(', ') ||
          '---'
        }
      />
      <Row>
        <InfoItem label="Time Zone" value={data?.timezone || '---'} />
        <InfoItem label="Project Type" value={data?.projectType || '---'} />
      </Row>
      <InfoItem label="Status Message" value={data?.statusMessage || '---'} />
      <InfoItem
        label="Owner"
        value={data?.owner ? `${(data.owner as TeamMember).name}` : '---'}
      />
      {data && data.projectManagementLink && (
        <>
          <Typography variant="h5" fontWeight={500}>
            Project
          </Typography>
          <Box sx={{ marginTop: '10px', marginBottom: '20px' }}>
            <LinkButton
              label="Project Dashboard"
              href={data?.projectManagementLink}
              target="_blank"
            />
          </Box>
        </>
      )}
      {subscriptionPortal && (
        <>
          <Typography variant="h5" fontWeight={500}>
            Subscription
          </Typography>
          <Box sx={{ marginTop: '10px', marginBottom: '20px' }}>
            <LinkButton
              label="Subscription Portal"
              href={subscriptionPortal}
              target="_blank"
            />
          </Box>
        </>
      )}
      <Typography variant="h5" fontWeight={500}>
        Members
      </Typography>
      {(!data?.members || data?.members.length === 0) && (
        <Typography marginTop={1.2}>No Members</Typography>
      )}
      <Box>
        <List>
          {data?.members &&
            data?.members.map((member, index) => (
              <div key={index}>
                <StyledListItem onClick={() => {}}>
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar {...stringAvatar(member.name || member.id)} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={member.name || member.id}
                      secondary={RoleLabelMapping[member.role]}
                    />
                  </ListItemButton>
                </StyledListItem>
              </div>
            ))}
        </List>
      </Box>
      <Row>
        <InfoItem
          label="Assembly Line Approved"
          value={data?.approved ? 'Yes' : 'No' || '---'}
        />
        <InfoItem
          label="Assembly Line Approved By"
          value={data?.approvedBy || '---'}
        />
      </Row>
      <ApproveDialog
        open={approveDialogOpen}
        onClose={handleCloseApproveDialog}
        allocation={data?.allocations[selectedAllocationIndex] as Allocation}
        onSuccess={onUpdate}
      />
    </Box>
  )
}

export default Assembly
