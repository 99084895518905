import { LinkButton } from '@assembly/components'
import { useAppStore } from '@assembly/hooks'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export default function BillingSetup() {
  const { store } = useAppStore()
  const { userInfo } = store
  const didMountRef = React.useRef<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [billingLink, setBillingLink] = React.useState<string>('')

  React.useEffect(() => {
    if (!userInfo) return

    if (!didMountRef.current) {
      if (!store.isAssembliesLoaded) {
        setBillingLink(
          `${process.env.REACT_APP_ENV_API_BASE_URL}/payments/org/${userInfo?.organizationID}/setup`
        )
        setLoading(false)
      } else {
        setLoading(false)
      }
      didMountRef.current = true
    }
  }, [userInfo])

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <LinkButton label="Setup" href={billingLink} target="_blank" />
    </Box>
  )
}
