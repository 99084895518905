import { axiosWithConfig } from '@assembly/config/axios'

export const createAssembly = (payload: any) => {
  return axiosWithConfig.post(`/client-dashboard/assembly_line/form`, payload)
}

export const getAssemblies = (payload: any) => {
  return axiosWithConfig.post(`/client-dashboard/assembly_line/search`, payload)
}

export const getAssembly = (id: string) => {
  return axiosWithConfig.get(`/client-dashboard/assembly_line/${id}`)
}

export const createAssemblyFromTemplate = (orgId: string, payload: any) => {
  return axiosWithConfig.post(
    `/client-dashboard/assembly_line/${orgId}/template`,
    payload
  )
}

export const getSubscriptionPortal = (assemblyId: string) => {
  return axiosWithConfig.get(
    `/payments/assembly/${assemblyId}/subscription/portal`
  )
}

export const acceptStartDate = (assemblyId: string, payload: any) => {
  return axiosWithConfig.post(`/client-dashboard/assembly_line/${assemblyId}/start_date/approve`, payload, {
    headers: {
      'Content-Type': "application/x-www-form-urlencoded"
    }
  })
} 