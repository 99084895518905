import {
  Assembly,
  Contractor,
  PayoutEntry,
  PayoutInvoice,
  AssemblyTemplate,
  User,
  TeamMember,
} from '@assembly/types'
import { createContext, useState, useMemo, useCallback, useEffect } from 'react'

type Store = {
  appBarTitle: string
  contractorProfile: Contractor
  isContractorProfileLoaded: boolean
  assemblies: Assembly[]
  isAssembliesLoaded: boolean
  payoutEntries: PayoutEntry[]
  isPayoutEntriesLoaded: boolean
  payoutInvoices: PayoutInvoice[]
  isPayoutInvoicesLoaded: boolean
  contract: string
  isContractLoaded: boolean
  assemblyTemplates: AssemblyTemplate[]
  isAssemblyTemplatesLoaded: boolean
  userInfo: User | null
  teamMembers: TeamMember[]
  isTeamMembersLoaded: boolean
}

interface IAppContext {
  store: Store
  setAppBarTitle: (title: string) => void
  setAssemblies: (assemblies: Assembly[]) => void
  setContractorProfile: (profile: Contractor) => void
  setPayoutEntries: (payoutEntries: PayoutEntry[]) => void
  setPayoutInvoices: (payoutInvoices: PayoutInvoice[]) => void
  setContract: (contract: string) => void
  setAssemblyTemplates: (assemblyTemplates: AssemblyTemplate[]) => void
  addNewAssembly: (assembly: Assembly) => void
  setTeamMembers: (teamMembers: TeamMember[]) => void
}

export const AppContext = createContext<IAppContext>({
  store: {
    appBarTitle: '',
    assemblies: [],
    isAssembliesLoaded: false,
    contractorProfile: {} as Contractor,
    isContractorProfileLoaded: false,
    payoutEntries: [],
    isPayoutEntriesLoaded: false,
    payoutInvoices: [],
    isPayoutInvoicesLoaded: false,
    contract: '',
    isContractLoaded: false,
    assemblyTemplates: [],
    isAssemblyTemplatesLoaded: false,
    userInfo: null,
    teamMembers: [],
    isTeamMembersLoaded: false,
  },
  setAppBarTitle: (title: string) => {},
  setAssemblies: (assemblies: Assembly[]) => {},
  setContractorProfile: (profile: Contractor) => {},
  setPayoutEntries: (payoutEntries: PayoutEntry[]) => {},
  setPayoutInvoices: (payoutInvoices: PayoutInvoice[]) => {},
  setContract: (contract: string) => {},
  setAssemblyTemplates: (assemblyTemplates: AssemblyTemplate[]) => {},
  addNewAssembly: (assembly: Assembly) => {},
  setTeamMembers: (teamMembers: TeamMember[]) => {},
})

interface AppProviderProps {
  children?: React.ReactNode
  user: User
}

export function AppProvider({ children, user }: AppProviderProps) {
  const [store, setStore] = useState<Store>({
    appBarTitle: '',
    assemblies: [],
    isAssembliesLoaded: false,
    contractorProfile: {} as Contractor,
    isContractorProfileLoaded: false,
    payoutEntries: [],
    isPayoutEntriesLoaded: false,
    payoutInvoices: [],
    isPayoutInvoicesLoaded: false,
    contract: '',
    isContractLoaded: false,
    assemblyTemplates: [],
    isAssemblyTemplatesLoaded: false,
    userInfo: null,
    teamMembers: [],
    isTeamMembersLoaded: false,
  })

  useEffect(() => {
    if (user) {
      setStore({
        ...store,
        userInfo: user,
      })
    }
  }, [user])

  const setAppBarTitle = useCallback((title: string) => {
    setStore((store) => ({
      ...store,
      appBarTitle: title,
    }))
  }, [])

  const setAssemblies = useCallback((assemblies: Assembly[]) => {
    setStore((store) => ({
      ...store,
      assemblies,
      isAssembliesLoaded: true,
    }))
  }, [])

  const addNewAssembly = useCallback((assembly: Assembly) => {
    setStore((store) => ({
      ...store,
      assemblies: [...store.assemblies, assembly],
    }))
  }, [])

  const setContractorProfile = useCallback((profile: Contractor) => {
    setStore((store) => ({
      ...store,
      contractorProfile: profile,
      isContractorProfileLoaded: true,
    }))
  }, [])

  const setPayoutEntries = useCallback((payoutEntries: PayoutEntry[]) => {
    setStore((store) => ({
      ...store,
      payoutEntries,
      isPayoutEntriesLoaded: true,
    }))
  }, [])

  const setPayoutInvoices = useCallback((payoutInvoices: PayoutInvoice[]) => {
    setStore((store) => ({
      ...store,
      payoutInvoices,
      isPayoutInvoicesLoaded: true,
    }))
  }, [])

  const setContract = useCallback((contract: string) => {
    setStore((store) => ({
      ...store,
      contract,
      isContractLoaded: true,
    }))
  }, [])

  const setAssemblyTemplates = useCallback(
    (assemblyTemplates: AssemblyTemplate[]) => {
      setStore((store) => ({
        ...store,
        assemblyTemplates,
        isAssemblyTemplatesLoaded: true,
      }))
    },
    []
  )

  const setTeamMembers = useCallback((teamMembers: TeamMember[]) => {
    setStore((store) => ({
      ...store,
      teamMembers,
      isTeamMembersLoaded: true,
    }))
  }, [])

  const value = useMemo(
    () => ({
      store,
      setAppBarTitle,
      setAssemblies,
      setContractorProfile,
      setPayoutEntries,
      setPayoutInvoices,
      setContract,
      setAssemblyTemplates,
      addNewAssembly,
      setTeamMembers,
    }),
    [
      store,
      setAppBarTitle,
      setAssemblies,
      setContractorProfile,
      setPayoutEntries,
      setPayoutInvoices,
      setContract,
      setAssemblyTemplates,
      addNewAssembly,
      setTeamMembers,
    ]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
