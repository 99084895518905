import { getAssemblies } from '@assembly/api/assembly'
import { NoDataMsg, SearchInputAndButton } from '@assembly/components'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import AssemblyList from './AssemplyList'
import { Assembly } from '@assembly/types'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'

export default function Assemblies() {
  const { store, setAssemblies } = useAppStore()
  const { userInfo, assemblies } = store
  const didMountRef = React.useRef<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [searchResults, setSearchResults] = React.useState<Assembly[]>()
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!userInfo) return

    if (!didMountRef.current) {
      if (!store.isAssembliesLoaded) {
        getAssembliesAsync()
      } else {
        setLoading(false)
      }
      didMountRef.current = true
    }
  }, [userInfo])

  const getAssembliesAsync = async () => {
    try {
      setLoading(true)
      const { data } = await getAssemblies({
        search: '',
        orgID: userInfo?.organizationID || '',
      })
      setAssemblies(data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      if (value) {
        const filtered = store.assemblies.filter(
          (assembly) =>
            assembly.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
        setSearchResults(filtered)
      } else {
        setSearchResults(undefined)
      }
    },
    [store.assemblies]
  )

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        inputPlaceholder="Search Assemblies"
        onChangeSearch={handleChangeSearch}
      />
      <AssemblyList assemblies={searchResults || assemblies} />
      {store.assemblies.length === 0 && (
        <NoDataMsg>No assemblies found</NoDataMsg>
      )}
    </Box>
  )
}
