import { axiosWithConfig } from '@assembly/config/axios'

export const getAllAssemblyTemplates = (payload: any) => {
  return axiosWithConfig.post(
    `/client-dashboard/assembly_line/templates/search`,
    payload
  )
}

export const getAssemblyTemplate = (id: string) => {
  return axiosWithConfig.get(`/client-dashboard/assembly_line/template/${id}`)
}
