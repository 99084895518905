import { Typography, colors } from '@mui/material'
import { styled } from '@mui/material/styles'

const NoDataMsg = styled(Typography)(() => ({
  fontSize: '16px',
  background: colors.grey[50],
  padding: '10px 20px',
  borderRadius: '5px',
}))

export default NoDataMsg
