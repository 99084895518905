import { getAllMembers } from '@assembly/api/team'
import { NoDataMsg, SearchInputAndButton } from '@assembly/components'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import InviteTeamMemberDialog from './InviteTeamMemberDialog'
import MembersList from './MembersList'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import { TeamMember } from '@assembly/types'

export default function Team() {
  const { setTeamMembers, store } = useAppStore()
  const [loading, setLoading] = React.useState<boolean>(true)
  const didMountRef = React.useRef(false)
  const [inviteTeamMemberDialogOpen, setInviteTeamMemberDialogOpen] =
    React.useState<boolean>(false)
  const { addAlert } = useSnackbars()
  const [searchResults, setSearchResults] = React.useState<TeamMember[]>()

  React.useEffect(() => {
    if (!didMountRef.current) {
      if (!store.isTeamMembersLoaded) {
        getAllMembersAsync()
      } else {
        setLoading(false)
      }
      didMountRef.current = true
    }
  }, [])

  const getAllMembersAsync = async () => {
    try {
      setLoading(true)
      const params = new URLSearchParams()
      params.append('search', '')
      const { data } = await getAllMembers(params)
      setTeamMembers(data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickInviteTeamMember = () => {
    setInviteTeamMemberDialogOpen(true)
  }

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      if (value) {
        const filtered = store.teamMembers.filter(
          (member) =>
            member.name?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            member.email?.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
        setSearchResults(filtered)
      } else {
        setSearchResults(undefined)
      }
    },
    [store.teamMembers]
  )

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        inputPlaceholder="Search"
        buttonLabel="Invite Team Member"
        onChangeSearch={handleChangeSearch}
        onClickButton={handleClickInviteTeamMember}
      />
      <MembersList teamMembers={searchResults || store.teamMembers} />
      {store.teamMembers.length === 0 && (
        <NoDataMsg>No members found</NoDataMsg>
      )}
      <InviteTeamMemberDialog
        open={inviteTeamMemberDialogOpen}
        onClose={() => setInviteTeamMemberDialogOpen(false)}
      />
    </Box>
  )
}
