import { Box, Button, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'

const PlusIcon = styled(AddIcon)({
  marginRight: '5px',
  width: '18px',
})

const ButtonStyled = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px',
  paddingRight: '12px',
  fontSize: '16px',
  fontWeight: 600,
})

const SearchIconStyled = styled(SearchIcon)({
  width: '25px',
})

const MainBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  width: '100%',
}))

const SearchInputBox = styled(Box)(({ theme }) => ({
  width: '500px',
  [theme.breakpoints.down('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '60px',
  marginBottom: '20px',
}))

type SearchInputProps = {
  onClickButton?: () => void
  buttonLabel?: string
  onChangeSearch: (value: string) => void
  inputPlaceholder: string
}

export default function SearchInputAndButton(props: SearchInputProps) {
  const { onChangeSearch, onClickButton, buttonLabel, inputPlaceholder } = props
  const [searchInput, setSearchInput] = React.useState<string>('')
  const didMount = React.useRef<boolean>(false)

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchInput(value)
  }

  React.useEffect(() => {
    if (didMount.current) {
      onChangeSearch(searchInput)
    } else {
      didMount.current = true
    }
  }, [searchInput, onChangeSearch])

  return (
    <MainBox sx={{ flexDirection: 'column-reverse' }}>
      <SearchInputBox>
        <TextField
          value={searchInput}
          margin="dense"
          id="name"
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          autoComplete="off"
          onChange={handleChangeSearch}
          placeholder={inputPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIconStyled />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: false }}
          sx={{ margin: 0 }}
        />
      </SearchInputBox>
      {buttonLabel && (
        <ButtonBox>
          <ButtonStyled variant="contained" onClick={onClickButton}>
            <PlusIcon />
            <span>{buttonLabel}</span>
          </ButtonStyled>
        </ButtonBox>
      )}
    </MainBox>
  )
}
