import { axiosWithConfig } from '@assembly/config/axios'

export const getAllMembers = (params: any) => {
  return axiosWithConfig.post(`/client-dashboard/team_member/search`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const getTeams = (params: any) => {
  return axiosWithConfig.post(`/client-dashboard/team/search`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const inviteTeamMember = (
  assemblyId: string,
  teamId: string,
  params: any
) => {
  return axiosWithConfig.post(
    `/auth/assembly/${assemblyId}/team/${teamId}/invite`,
    params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
}
