import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import {
  Chip,
  CircularProgress,
  ListItemButton,
  Typography,
  colors,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AssemblyTemplate, Capability } from '@assembly/types'
import { RoutePaths } from '@assembly/constants'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import { formatNumbersWithCommas, interpolateRouteUtil } from '@assembly/utils'
import React from 'react'
import { createAssemblyFromTemplate } from '@assembly/api/assembly'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { theme } from '@assembly/theme'

interface AssemblyTemplatesListProps {
  assemblyTemplates: AssemblyTemplate[]
}

export default function AssemblyTemplatesList(
  props: AssemblyTemplatesListProps
) {
  return (
    <List>
      {props.assemblyTemplates.map((assemblyTemplate, index) => (
        <ListItemTemplate key={index} assemblyTemplate={assemblyTemplate} />
      ))}
    </List>
  )
}

const ListItemTemplate = ({
  assemblyTemplate,
}: {
  assemblyTemplate: AssemblyTemplate
}) => {
  const navigate = useNavigate()
  const handleClickAssemblyTemplate = (id: string) => {
    navigate(
      interpolateRouteUtil(RoutePaths.AssemblyTemplate, {
        id,
      })
    )
  }
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { store, addNewAssembly } = useAppStore()
  const { addAlert } = useSnackbars()

  const handleClickCreate = async () => {
    if (!store.userInfo?.organizationID) {
      return
    }

    try {
      setIsLoading(true)
      const payload = {
        name: assemblyTemplate.name,
        description: assemblyTemplate.description,
        capabilities: assemblyTemplate.capabilities.map((c) => c.id),
        allocations: assemblyTemplate.allocations,
        approximateMonthlyBudget: assemblyTemplate.approximateMonthlyBudget,
      }
      const { data } = await createAssemblyFromTemplate(
        store.userInfo?.organizationID,
        payload
      )
      addNewAssembly(data)
      setIsLoading(false)
      navigate(interpolateRouteUtil(RoutePaths.Assembly, { id: data.id }))
    } catch (error: any) {
      setIsLoading(false)
      console.error(error)
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        typeof error.response.data.message === 'string'
      ) {
        addAlert({
          message: error.response.data.message,
          type: SnackbarType.Error,
        })
      }

      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        addAlert({
          message: error.response.data.messages.map((m: any) => m).join(', '),
          type: SnackbarType.Error,
        })
      }
    }
  }

  return (
    <ListItem
      sx={{
        padding: 0,
        border: '1px solid',
        borderColor: colors.grey[200],
        borderRadius: '5px',
        marginBottom: 2,
      }}
      secondaryAction={
        <Box display="flex" gap={3}>
          <Tooltip title="Create Assembly">
            <IconButton
              onClick={handleClickCreate}
              edge="end"
              aria-label="Create Assembly"
              sx={{ color: colors.grey[800] }}
            >
              {isLoading ? <CircularProgress size={20} /> : <AddCircleIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      }
    >
      <ListItemButton
        onClick={() => handleClickAssemblyTemplate(assemblyTemplate.id)}
        sx={{ display: 'flex', alignItems: 'flex-start' }}
      >
        <ListItemAvatar sx={{ marginTop: '8px' }}>
          <Avatar sx={{ bgcolor: '#1b17f514', color: '#000' }}>
            <BlurLinearIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="span" variant="h6" fontWeight={500}>
              {assemblyTemplate.name}
            </Typography>
          }
          secondary={
            <Box component="span">
              <Typography
                component="span"
                variant="body2"
                marginBottom="10px"
                marginTop="5px"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                {assemblyTemplate.description}
              </Typography>
              <Box component="span">
                <Box
                  marginBottom="10px"
                  component="span"
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                >
                  {assemblyTemplate.capabilities.map(
                    (c: Capability, index: number) => (
                      <Chip
                        key={index}
                        label={c.name}
                        color="primary"
                        variant="outlined"
                        component="span"
                      />
                    )
                  )}
                </Box>
                <Typography component="span" color="secondary">
                  $
                  {formatNumbersWithCommas(
                    Number(assemblyTemplate.approximateMonthlyBudget)
                  )}
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
