export const SAMPLE_WORK_PLACEHOLDER_COVER =
  'https://placehold.jp/30/4F59CC/ffffff/300x150.png?text=Cover'

export const getMediaUrl = (mediaPath: string) => {
  if (!mediaPath) {
    return
  }

  return process.env.REACT_APP_ENV_MEDIA_CDN_URL + mediaPath
}
