import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { CapabilitiesSearchAndSelect } from '@assembly/components'
import { Capability } from '@assembly/types'
import { CreateAssemblyForm, ICreateAssembly } from '@assembly/types'
import {
  getApiErrorMsg,
  interpolateRouteUtil,
  timezones,
} from '@assembly/utils'
import { RoutePaths, defaultTimezone } from '@assembly/constants'
import { createAssembly } from '@assembly/api/assembly'
import { useNavigate } from 'react-router-dom'

interface CreateAssemblyTemplateDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateAssemblyTemplateDialogTitle(
  props: CreateAssemblyTemplateDialogTitleProps
) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateAssemblyTemplateDialogProps {
  open: boolean
  onClose: () => void
}

export default function CreateAssemblyDialog(
  props: CreateAssemblyTemplateDialogProps
) {
  const { open, onClose } = props
  const formInitialValues: CreateAssemblyForm = {
    name: '',
    description: '',
    capabilities: [],
    monthlyBudget: null,
    timezone: defaultTimezone,
    invalid_name: false,
    invalid_description: false,
    invalid_capabilities: false,
    invalid_monthlyBudget: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const { addAlert } = useSnackbars()
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { userInfo } = useAppStore().store
  const { addNewAssembly } = useAppStore()
  const navigate = useNavigate()

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.name.length === 0 ||
      formValues.description.length === 0 ||
      formValues.monthlyBudget === null ||
      formValues.capabilities.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_name: formValues.name.length === 0,
        invalid_description: formValues.description.length === 0,
        invalid_monthlyBudget: formValues.monthlyBudget === null,
        invalid_capabilities: formValues.capabilities.length === 0,
      })
      return
    }

    setIsSaving(true)
    const payload: ICreateAssembly = {
      name: formValues.name,
      description: formValues.description,
      capabilities: formValues.capabilities.map((capability) => capability.id),
      monthlyBudget: Number(formValues.monthlyBudget),
      timezone: formValues.timezone,
      orgID: userInfo?.organizationID || '',
    }

    try {
      const { data } = await createAssembly(payload)
      setIsSaving(false)
      addAlert({
        message: 'Assembly Created!',
        type: SnackbarType.Success,
      })
      addNewAssembly(data)
      handleClose()
      navigate(interpolateRouteUtil(RoutePaths.Assembly, { id: data.id }))
    } catch (error: any) {
      setIsSaving(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeCapabilities = (capabilities: Capability[]) => {
    setFormValues({
      ...formValues,
      capabilities,
      invalid_capabilities: false,
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setIsSaving(false)
    onClose()
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateAssemblyTemplateDialogTitle onClose={handleClose}>
          Create Assembly
        </CreateAssemblyTemplateDialogTitle>
        <DialogContent dividers>
          <TextField
            value={formValues.name}
            margin="dense"
            label="Name"
            type="text"
            name="name"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_name}
            helperText={formValues.invalid_name ? 'Required' : ''}
          />
          <TextField
            value={formValues.description}
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            error={formValues.invalid_description}
            onChange={handleChangeFormField}
            helperText={formValues.invalid_description ? 'Required' : ''}
            sx={{ margin: 0, marginTop: '20px' }}
            multiline
            rows={10}
          />
          <Box marginTop="20px">
            <CapabilitiesSearchAndSelect
              onChange={handleChangeCapabilities}
              value={formValues.capabilities}
              error={formValues.invalid_capabilities}
              helperText={formValues.invalid_capabilities ? 'Required' : ''}
            />
          </Box>
          <Box marginTop="20px">
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Timezone</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={formValues.timezone || ''}
                label="Timezone"
                name="timezone"
                onChange={handleChangeSelect}
              >
                {timezones.map((timezone, index) => (
                  <MenuItem key={index} value={timezone.value}>
                    {timezone.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TextField
            value={formValues.monthlyBudget || ''}
            margin="dense"
            name="monthlyBudget"
            label="Monthly Budget"
            type="number"
            fullWidth
            error={formValues.invalid_monthlyBudget}
            onChange={handleChangeFormField}
            helperText={formValues.invalid_monthlyBudget ? 'Required' : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            sx={{ margin: 0, marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
