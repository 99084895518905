import { Box, Typography, colors } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)({
  backgroundColor: colors.grey[50],
  padding: '15px',
  borderRadius: '10px',
  width: '100%',
  marginBottom: '15px',
})

export default function InfoItem({
  label,
  value,
}: {
  label: string
  value: string | any
}) {
  return (
    <StyledBox>
      <Typography variant="h6">{label}</Typography>
      <Typography>{value}</Typography>
    </StyledBox>
  )
}
