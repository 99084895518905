import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import {
  Avatar,
  Chip,
  ListItemAvatar,
  ListItemButton,
  Typography,
  colors,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Capability } from '@assembly/types'
import { RoutePaths } from '@assembly/constants'
import { formatNumbersWithCommas, interpolateRouteUtil } from '@assembly/utils'
import LinearScaleIcon from '@mui/icons-material/LinearScale'

interface AssemblyListProps {
  assemblies: any[]
}

export default function AssemblyList(props: AssemblyListProps) {
  const navigate = useNavigate()
  const handleClickAssemblyTemplate = (id: string) => {
    navigate(
      interpolateRouteUtil(RoutePaths.Assembly, {
        id,
      })
    )
  }

  return (
    <List>
      {props.assemblies.map((assembly, index) => {
        if (!assembly) return null

        return (
          <div key={index}>
            <ListItem
              sx={{
                padding: 0,
                border: '1px solid',
                borderColor: colors.grey[200],
                borderRadius: '5px',
                marginBottom: 2,
              }}
            >
              <ListItemButton
                onClick={() => handleClickAssemblyTemplate(assembly.id)}
                sx={{ display: 'flex', alignItems: 'flex-start' }}
              >
                <ListItemAvatar sx={{ marginTop: '8px' }}>
                  <Avatar sx={{ bgcolor: '#1b17f514', color: '#000' }}>
                    <LinearScaleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6" fontWeight={500}>
                      {assembly.name}
                    </Typography>
                  }
                  secondary={
                    <Box component="span">
                      <Typography
                        variant="body2"
                        marginBottom="10px"
                        marginTop="5px"
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}
                      >
                        {assembly.description}
                      </Typography>
                      <Box component="span">
                        <Box
                          component="span"
                          display="flex"
                          flexWrap="wrap"
                          gap={1}
                        >
                          {assembly.capabilities.map(
                            (c: Capability, index: number) => (
                              <Chip
                                key={index}
                                label={c.name}
                                color="primary"
                                variant="outlined"
                              />
                            )
                          )}
                        </Box>
                        <Typography color="secondary" marginTop="10px">
                          $
                          {formatNumbersWithCommas(
                            assembly.monthlySubscriptionCost
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          </div>
        )
      })}
    </List>
  )
}
