import { axiosWithConfig } from '@assembly/config/axios'

const path = `/admin/contractor`

export const getAllResources = (searchStr?: string) => {
  const params = new URLSearchParams()
  params.append('search', searchStr || '')
  return axiosWithConfig.post(`${path}/search`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}


export const approveResource = (id: string, assemblyId: string, payload: any) => {
  return axiosWithConfig.post(`/client-dashboard/assembly_line/${assemblyId}/allocation/${id}/approve`, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}