import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, TextField, Typography, CircularProgress } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import logo from '@assembly/assets/logo.png'
import { getOneTimeCode, login } from '@assembly/api/auth'
import validator from 'validator'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'

const Container = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
}))

const BoxLeft = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const BoxRight = styled(Box)(({ theme }) => ({
  background:
    'linear-gradient(90deg, hsla(235, 55%, 55%, 1) 0%, hsla(290, 25%, 76%, 1) 91%)',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Space Grotesk',
  fontSize: '40px',
  fontWeight: 500,
}))

const Description = styled(Typography)(() => ({
  fontFamily: 'Space Grotesk',
  fontSize: '18px',
  marginTop: '5px',
  fontWeight: 400,
}))

const EmailInput = styled(TextField)(() => ({
  marginTop: '48px',
  fieldset: {
    borderRadius: '10px',
  },
  input: {
    fontFamily: 'Space Grotesk',
  },
}))

const BoxLeftInnerContianer = styled(Box)(({ theme }) => ({
  width: '70%',
  [theme.breakpoints.up('xl')]: {
    width: '540px',
  },
}))

const ActionButton = styled(LoadingButton)(({ theme }) => ({
  height: '60px',
  width: '100%',
  borderRadius: '35px',
  fontFamily: 'Lato',
  letterSpacing: '1px',
  fontSize: '16px',
  fontWeight: 400,
  marginTop: '30px',
  boxShadow: '0px 30px 40px -20px #4F59CC99',
  ':hover': {
    boxShadow: '0px 30px 40px -20px #4F59CC99',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
  },
}))

// const DontHaveAccountText = styled(Typography)(() => ({
//   color: '#A6A6A6',
//   fontFamily: 'Space Grotesk',
//   fontWeight: 400,
//   marginTop: '28px',
//   textAlign: 'center',
// }))

const ArrowIcon = styled(ArrowForwardIcon)(() => ({
  marginLeft: '5px',
}))

const Logo = styled('img')(() => ({
  width: '211px',
}))

const Header = styled(Box)(() => ({
  height: '25%',
  marginTop: '25px',
}))

const OtpInputBox = styled(Box)(() => ({
  marginTop: '48px',
}))

const OtpInputLoadingBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '40px',
}))

const OtpErrorText = styled(Typography)(() => ({
  color: 'red',
  marginTop: '15px',
  textAlign: 'center',
}))

export default function Login() {
  const [email, setEmail] = React.useState<string>('')
  const [invalid_email, setInvalid_email] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [otp, setOtp] = React.useState('')
  const [isOtpInputVisible, setIsOtpInputVisible] =
    React.useState<boolean>(false)
  const [otpError, setOtpError] = React.useState<string>('')
  const { addAlert } = useSnackbars()

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setInvalid_email(false)
  }

  const handleClickLogin = async () => {
    if (email.length === 0 || !validator.isEmail(email)) {
      setInvalid_email(true)
      return
    }

    try {
      const params = new URLSearchParams()
      params.append('email', email)
      setIsLoading(true)
      await getOneTimeCode(params)
      setIsOtpInputVisible(true)
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        addAlert({
          message: error.response.data.message,
          type: SnackbarType.Error,
        })
      }
    }
  }

  const submitOtp = async (otp: string) => {
    try {
      setIsLoading(true)

      await login({ email: encodeURIComponent(email), code: otp })
      window.location.reload()
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setOtpError(error.response.data.message)
      }
    }
  }

  const handleChangeOtp = (newValue: string) => {
    setOtp(newValue)
    setOtpError('')
  }

  const validateChar = (value: string) => {
    return !value || isDigit(value)
  }

  function isDigit(value: any) {
    return /^\d$/.test(value)
  }

  const handleOtpComplete = (value: string) => {
    submitOtp(value)
  }

  return (
    <Container>
      <BoxLeft>
        <BoxLeftInnerContianer>
          <Header>
            <Logo src={logo} alt="logo" />
          </Header>
          <Title>{isOtpInputVisible ? 'Enter OTP' : 'Welcome back!'}</Title>
          <Description>
            {isOtpInputVisible ? (
              <>
                Please check your email{' '}
                <Typography component="span" color="primary">
                  {email}
                </Typography>
                . We've sent you a one time code.
              </>
            ) : (
              'Hiring the Top 1% Talent to get your most critical operational work done.'
            )}
          </Description>
          {isOtpInputVisible ? (
            <OtpInputBox>
              <MuiOtpInput
                autoFocus
                value={otp}
                onChange={handleChangeOtp}
                onComplete={handleOtpComplete}
                length={7}
                validateChar={validateChar}
                TextFieldsProps={{
                  inputProps: {
                    style: { fontSize: '25px', fontFamily: 'Lato' },
                  },
                  placeholder: '-',
                  disabled: isLoading,
                }}
              />
              {isLoading && (
                <OtpInputLoadingBox>
                  <CircularProgress />
                </OtpInputLoadingBox>
              )}
              {otpError && <OtpErrorText>{otpError}</OtpErrorText>}
            </OtpInputBox>
          ) : (
            <EmailInput
              type="email"
              variant="outlined"
              label="Email Address"
              fullWidth
              onChange={handleChangeEmail}
              value={email}
              error={invalid_email}
              helperText={
                invalid_email
                  ? email.length === 0
                    ? 'Please enter email address'
                    : 'Invalid email address'
                  : null
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClickLogin()
                }
              }}
            />
          )}
          {!isOtpInputVisible && (
            <>
              <ActionButton
                variant="contained"
                onClick={handleClickLogin}
                loading={isLoading}
              >
                SEND CODE TO EMAIL TO LOGIN
                <ArrowIcon />
              </ActionButton>
              {/* <DontHaveAccountText>
                Don't have an account? <Link>Sign up for access</Link>
              </DontHaveAccountText> */}
            </>
          )}
        </BoxLeftInnerContianer>
      </BoxLeft>
      <BoxRight></BoxRight>
    </Container>
  )
}
