import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Allocation } from '@assembly/types'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { approveResource } from '@assembly/api/resources'
import { useParams } from 'react-router-dom'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'

type ApproveDialogProps = {
  open: boolean
  onClose: () => void
  allocation: Allocation
  onSuccess: (allocation: Allocation) => void
}

interface ApproveDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function ApproveDialogTitle(props: ApproveDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function ApproveDialog({
  open,
  onClose,
  allocation,
  onSuccess,
}: ApproveDialogProps) {
  const [value, setValue] = React.useState('Owner')
  const [isSaving, setIsSaving] = React.useState(false)
  const params = useParams()
  const { addAlert } = useSnackbars()

  const handleClose = () => {
    setValue('Owner')
    onClose()
  }

  const handleClickApprove = async () => {
    if (allocation.resource?.id === undefined || params.id === undefined) {
      return
    }
    setIsSaving(true)

    const searchParams = new URLSearchParams()
    searchParams.append('approver', value)
    try {
      const { data } = await approveResource(
        allocation.resource?.id,
        params.id,
        searchParams
      )
      onSuccess(data)
      setIsSaving(false)
      addAlert({
        message: 'Your request has been received',
        type: SnackbarType.Success,
      })
      setValue('Owner')
    } catch (error: any) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setIsSaving(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ApproveDialogTitle onClose={handleClose}>Approve</ApproveDialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          label="Approve By"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={isSaving} onClick={handleClickApprove}>
          Approve
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
