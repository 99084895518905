import { Allocation } from './assemblyTemplate'
import { Capability } from './capability'

export type ICreateAssembly = {
  id?: string
  name: string
  description: string
  capabilities: string[]
  monthlyBudget: number | null
  timezone: string
  orgID: string
}

export type Assembly = {
  id: string
  name: string
  description: string
  orgID: string
  capabilities: Capability[]
  monthlySubscriptionCost: number | null
  subscriptionSetupComplete: boolean
  allocations: Allocation[]
  startDate: number
  endDate: number
  timezone: string
  owner: string | TeamMember | null
  members: TeamMember[]
  funding: Funding
  approved: boolean
  approvedBy: string
  projectType: string
  status: string
  statusMessage: string
  projectManagementLink: string
  checklists: any
}

export type Funding = {
  billingAmount: number | null
  currency: string
  frequency: string
  startDate: string
  endDate: string
  lastSuccessfulPayment: number | null
  status: string
  statusMsg: string
  setupLink: string
  paymentLink: string
  apContacts: string[]
}

type ProjectManagement = {
  projectManagementLink: string
  submitTaskLink: string
  kanbanLink: string
  teamID: string
  projectID: string
}

export type TeamMember = {
  id: string
  organizationID: string
  primaryTeam: string
  name: string
  email: string
  phone: string
  assemblyLines: string[]
  role: Roles
  oneTimeCode: string
  oneTimeCodeExpiration: number
  emailVerified: boolean
  lastSession: number
}

export enum Roles {
  AssemblyLineAdmin = 'ASSEMBLY_LINE_ADMIN',
  AssemblyLineContributor = 'ASSEMBLY_LINE_CONTRIBUTOR',
}

export const RoleLabelMapping = {
  [Roles.AssemblyLineAdmin]: 'Assembly Line Admin',
  [Roles.AssemblyLineContributor]: 'Assembly Line Contributor',
}


export enum AssemblyLineStatus {
  Initialized = 'INITIALIZED',
  Setup = 'SETUP',
  Proposal = 'PROPOSAL',
  Provisioning = 'PROVISIONING',
  Ready = 'READY',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Complete = 'COMPLETE',
  Archived = 'ARCHIVED',
  Modify = 'MODIFY',
}