import { getAssemblyTemplate } from '@assembly/api/assemblyTemplate'
import { useAppStore } from '@assembly/hooks'
import {
  Avatar,
  Box,
  CardActionArea,
  CircularProgress,
  Paper,
  Typography,
  colors,
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { AssemblyTemplate as IAssemblyTemplate } from '@assembly/types'
import { InfoItem } from '@assembly/components'
import {
  formatNumbersWithCommas,
  getMediaUrl,
  stringAvatar,
} from '@assembly/utils'
import { capitalize } from 'lodash'

const AssemblyTemplate: React.FC = () => {
  const { setAppBarTitle } = useAppStore()
  const didMount = React.useRef(false)
  const params = useParams()
  const [data, setData] = React.useState<IAssemblyTemplate>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (!didMount.current) {
      getDataAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getDataAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAssemblyTemplate(params.id || '')
      setAppBarTitle(data.name)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleClickAllocation = (id: string) => {
    if (!id) return

    const partnerDashboardUrl = process.env.REACT_APP_ENV_PARTNER_DASHBOARD_URL
    window.open(`${partnerDashboardUrl}/talent/${id}`, '_blank')
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <InfoItem label="Name" value={data?.name || ''} />
      <InfoItem label="Description" value={data?.description || ''} />
      <InfoItem
        label="Approximate Monthly Budget"
        value={
          data?.approximateMonthlyBudget
            ? '$' + formatNumbersWithCommas(data?.approximateMonthlyBudget)
            : ''
        }
      />
      <InfoItem
        label="Capabilities"
        value={
          data?.capabilities?.map((capability) => capability.name).join(', ') ||
          ''
        }
      />
      <Typography variant="h5" fontWeight={500}>
        Allocations
      </Typography>
      {data?.allocations.length === 0 && (
        <Typography marginTop={1.2}>No Allocations</Typography>
      )}
      {data?.allocations.map((allocation, index) => (
        <CardActionArea
          key={index}
          onClick={() =>
            handleClickAllocation(allocation.resource?.id as string)
          }
          sx={{
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <Paper
            sx={{
              padding: 2,
              boxShadow: 'none',
              border: '1px solid',
              borderColor: colors.grey[200],
            }}
          >
            <Box display="flex" alignItems="center" marginBottom={1}>
              <Avatar
                {...stringAvatar(allocation.resource?.firstName || '')}
                src={getMediaUrl(
                  allocation.resource?.profilePicture?.media || ''
                )}
              />
              <Box>
                <Typography marginLeft={2} fontSize={17}>
                  {allocation.resource?.firstName +
                    ' ' +
                    allocation.resource?.lastName}
                </Typography>
                <Typography variant="body2" marginLeft={2}>
                  {allocation.resource?.label}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2} marginLeft={7}>
              <Typography>
                <b>Frequency: </b>
                {allocation.rate} {capitalize(allocation.rateUnit)}{' '}
                {capitalize(allocation.frequency)}
              </Typography>
              <Typography>
                <b>Amount: </b>${allocation.amount} USD
              </Typography>
              <Typography>
                <b>Status: </b>
                {capitalize(allocation.status)}
              </Typography>
              <Typography>
                <b>Approved: </b>
                {allocation.approved ? 'Yes' : 'No'}
              </Typography>
              <Typography>
                <b>Approved By: </b>
                {allocation.approvedBy || '---'}
              </Typography>
            </Box>
          </Paper>
        </CardActionArea>
      ))}
    </Box>
  )
}

export default AssemblyTemplate
