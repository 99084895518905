import { RoutePaths } from '@assembly/constants'
import axios from 'axios'

const baseURL = process.env.REACT_APP_ENV_API_BASE_URL

export const axiosWithConfig = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

axiosWithConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      window.location.href = RoutePaths.Root
    }
    return Promise.reject(error)
  }
)

export const axiosWithConfigForAuth = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})
