import { axiosWithConfigForAuth } from '@assembly/config/axios'

export const getOneTimeCode = (payload: any) => {
  return axiosWithConfigForAuth.post(
    `/auth/team_member/one-time-code`,
    payload,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
}

export const login = (payload: any) => {
  return axiosWithConfigForAuth.get(
    `/auth/team_member/login?email=${payload.email}&code=${payload.code}`
  )
}

export const checkSessionIsValid = () => {
  return axiosWithConfigForAuth.get(`/auth/session/team_member`)
}

export const logout = () => {
  return axiosWithConfigForAuth.get(`/auth/team_member/logout`).then(() => {
    window.location.href = '/'
  })
}
