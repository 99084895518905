import { axiosWithConfig } from '@assembly/config/axios'

export const getAllCapabilites = (payload: any) => {
  return axiosWithConfig.post(
    `/client-dashboard/assembly_line/capabilities/search`,
    payload,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
  )
}
