import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material'
import { Assembly } from '@assembly/types'
import { getAssemblies } from '@assembly/api/assembly'
import { useAppStore } from '@assembly/hooks'

type AssemblySearchAndSelectProps = {
  onChange: (assembly: Assembly) => void
  value: Assembly | null
  error: boolean
  helperText: string
}

export default function AssemblySearchAndSelect({
  onChange,
  value,
  error,
  helperText,
}: AssemblySearchAndSelectProps) {
  const [options, setOptions] = React.useState<Assembly[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const { userInfo } = useAppStore().store
  const didMountRef = React.useRef(false)

  React.useEffect(() => {
    if (!didMountRef.current) {
      getAssembliesAsync()
      didMountRef.current = true
    }
  }, [])

  const getAssembliesAsync = async () => {
    try {
      setLoading(true)
      const { data } = await getAssemblies({
        search: '',
        orgID: userInfo?.organizationID,
      })
      setOptions(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Autocomplete
      loading={loading}
      getOptionLabel={(option) => option.name}
      options={options}
      autoComplete
      includeInputInList
      value={value}
      noOptionsText="No Assemblies found"
      onChange={(_: any, newValue: any) => {
        onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Assembly"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Grid
              item
              sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
            >
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {option.name}
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                {option.description}
              </Typography>
            </Grid>
          </li>
        )
      }}
    />
  )
}
